import HorizontalScroll from "../Components/original copy";
import ScrollDetection from "../Components/scrollDetection";
import React, { useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';

const Land = () => {

  return (
    <div>
      <HorizontalScroll />
    </div>
  );
};

export default Land;