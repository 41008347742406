import React, { useRef, useEffect, useState } from 'react';
import land1 from '../assets/IMG_4397.png';
import land2 from '../assets/IMG_4406.PNG';
import land3 from '../assets/IMG_4413.PNG';
import element1 from '../assets/element1.png';
import element2 from '../assets/element2.png';
import element3 from '../assets/element3.png';
import element4 from '../assets/element4.png';
import element5 from '../assets/element5.png';
import element6 from '../assets/element6.png';
import element7 from '../assets/element7.png';
import element8 from '../assets/element8.png';
import element9 from '../assets/element9.png';
import element10 from '../assets/element10.png';
import element11 from '../assets/element11.png';
import whatsapp from '../assets/whatsapp.png';
import logo from '../assets/logo.png';
import $ from "jquery";
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import landM from '../assets/mobile-1.png';
import right from '../assets/right-arrow.png';
import left from '../assets/left-arrow.png';

$(window).on('load', function () {
    setTimeout(function () { // allowing 3 secs to fade out loader
        $('.page-loader').fadeOut('slow');
    });
});

const services = () => {
    window.location.href = "https://creaters.pages.dev/services";
}

const HorizontalScroll = () => {
    const containerRef = useRef(null);
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const detailsEggs = [
        {
            id: 'landsBG',
            title: 'Gold Verse',
            description: 'Our institute offers comprehensive 3D animation courses designed to equip students with the skills and knowledge needed to excel in the animation industry. Our curriculum covers a wide range of topics, including character modeling, rigging, texturing, and animation principles. With state-of-the-art facilities and experienced instructors, students gain hands-on experience using industry-standard software and tools. Whether youre a beginner or looking to advance your skills, our courses provide a solid foundation and the creative environment needed to bring your animated visions to life. Join us to turn your passion for animation into a rewarding career.',
            imgSrc: 'https://lands-imgs.pages.dev/imgs/IMG_4397.PNG'
        },
        {
            id: 'landsBG',
            title: 'Education',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://lands-imgs.pages.dev/imgs/IMG_4406.PNG'
        },
        {
            id: 'landsBG',
            title: 'Gaming Services',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://lands-imgs.pages.dev/imgs/IMG_4413.PNG',
            link: 'book'
        },
    ];

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % detailsEggs.length);
    };

    const showPrevious = () => {
        setCurrentIndex((currentIndex - 1 + detailsEggs.length) % detailsEggs.length);
    };

    const handleEggClick = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const handleWheel = (e) => {
            const container = containerRef.current;
            if (container) {
                container.scrollLeft += e.deltaY;
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    return (
        <div>
            <div className='imgsMainDiv' ref={containerRef}>
               {/**<div className='sky'></div>*/}
                <div class="page-loader">
                    <div class="loader"></div>
                </div>
                {/*<div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv">Services</div>
                            <div class="navDiv">Contact Us</div>
                            <div class="navDiv">Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                        </div>

                    </div>

                </div>*/}
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv">Services</div>
                        <div class="navDiv">Contact Us</div>
                        <div class="navDiv">Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                    </div>) : null}
                <div id='landMain'>
                    <img className='img1' src={land1} />
                    <img className='img2' src={land2} onClick={() => window.location.href = 'services'}/>
                    <img className='img3' src={land3} />

                    <img className='element1' src={element1} />
                    <img className='element2' src={element2} />
                    <img className='element3' src={element3} />
                    <img className='element4' src={element4} />
                    <img className='element5' src={element5} />
                    <img className='element6' src={element6} />
                    <img className='element7' src={element7} />
                    <img className='element8' src={element8} onClick={services} />
                    <img className='element9' src={element9} />
                    <img className='element10' src={element10} />
                    <img className='element11' src={element11} />

                    <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

                    <img className='bgImgs' src='https://lands-imgs.pages.dev/imgs/bg.png' />

                    <div className='landD'>
                        <div className='landH'>Welcome to Our Spaces</div>
                        <p className='landCon'>Discover Gold Verse, a premier space for luxurious living and elite experiences. Explore EduSphere, our hub for innovative learning and educational excellence. Dive into Game Haven, your ultimate destination for immersive gaming adventures. Welcome to a world of endless possibilities.</p>
                    </div>
                </div>
            </div>

            <div className='allwrapLands'>
                <div class="page-loader">
                    <div class="loader"></div>
                </div>
                <div className="headers">
                    <div className="headers2">
                        <div className="logo" onClick={() => window.location.href = '/'}>
                            <img className="logoPic" onClick={() => window.location.href = '/'} src={logo} alt="Logo" />
                        </div>
                        <div className="right">
                            <div className="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div className="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div className="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                        </div>
                    </div>
                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                    </div>) : null}


                <div className='landsSection'>
                    <div className='carouselContainerLands'>
                        <img className='arrow left' onClick={showPrevious} src={left} alt="Previous" />
                        <div className='eggsSection'>
                            {detailsEggs.map((egg, index) => (
                                <div className={`detailsEgg ${index === currentIndex ? 'active' : ''}`} key={egg.id}>
                                    <div className={egg.id}>
                                        <div className='eggT'>{egg.title}</div>
                                        <img className='landImg' src={egg.imgSrc} alt={egg.title} onClick={() => window.location.href = egg.link} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img className='arrow right-2' onClick={showNext} src={right} alt="Next" />
                    </div>

                </div>


                <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} alt="WhatsApp" /></div>
            </div>
        </div>
    );
};

export default HorizontalScroll;
